/* 
@media (min-width: 1366px){
.users_row > div{
    flex: 0 0 1;
    max-width: calc(100% / 7);
}


}
.userlevelediticon{
    color: #2972b6;
    font-size: 11px;
    padding-left: 8px;
} */

/* set date-picker width 100% */
.react-datepicker-wrapper{
    width: 100% !important;
}

/* hide dropdown toggle icon */
.dropdown-toggle::after {
    display: none !important;
  }

/* secondary btn */
.btn-outline-secondary{
    border-color: rgba(34,36,38,0.15);
}