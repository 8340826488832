body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table td,
.table th {
  white-space: nowrap;
}

i.icon.sidebartoggler_icon:before {
  font-size: 20px !important;
}

.nav-item.dropdown {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}

.commonCard_style.card {
  padding: 20px;
  height: 100%;
}
.graphRow {
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 25px 0;
}


.arrowRotate{
  transform: rotate(90deg);
}
.dropdown__list {
  list-style: none;
  padding-left: 0px;
 
}
.dropdown__list.d-none, .dropdown__list.d-block{
  transition: all linear 0.3s;
}
.dropdown__list .dropdown__item{
/* padding: 10px; */
font-size: 11px;

}

.dropdown__link{
  padding: 5px;
}

.kycImgae_Wrap{
  width: 100%;
  height: 410px
}

.kycImgae_Wrap img {
  width: 100%;
  height: 100%;
  
}

.kycCard{
  margin-right: 100px;
  margin-left: 16px;
 
}
