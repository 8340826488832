:root {
  --primary-color: #f37022;
  --primary-hover-color: #d8580b;
}

#root {
  width: 100%;
  height: 100%;
}
#root > div {
  width: inherit;
  height: inherit;
}

.PublicArea__content {
  background-image: url('./images/login-bg.png');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .ui.pagination.menu > a {
    min-width: auto !important;
    padding: 6px 8px !important;
    height: auto !important;
    min-height: auto !important;
  }
}

/* .toggler_icon {
  background-image: url('./images/togglerImg.png');
    background-position: center;
    background-repeat: no-repeat;
    content: "";
    background-size: contain;
    height: 20px;
    width: 20px;

} */

@media (max-width: 767px) {
  .main_navbar_container {
    max-width: 200px;
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
  right: -19px !important;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

button.btn.btn-primary {
  white-space: nowrap;
}

.text-primary {
  color: var(--primary-color) !important;
}

.btn-primary {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:focus-visible {
  background-color: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
  box-shadow: 0 0 0 3px rgba(243, 112, 34, 0.15),
    0 3px 15px rgba(243, 112, 34, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.Login .modal-content > .modal-body {
  padding: 0 !important;
}
.Login .modal-content > .modal-body > div > div {
  padding: 1.875rem 2.1875rem;
}

@media  (min-width: 320px) { 
  .modal-body .login-icon-box {
    display: none;
  }
}

@media  (min-width: 992px) { 
  .modal-body .login-icon-box {
    display: block;
  }
}