.table-responsive {
    overflow-x: auto;
    position: relative;
  }
  
  .fixed-table {
    min-width: 1000px;
  }
  
  .fixed-table th.action-col,
  .fixed-table td.action-col {
    position: sticky;
    right: 0;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .fixed-table th.action-col {
    z-index: 2; /* Ensures the header column stays above other content */
  }