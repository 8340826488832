.editable {
    margin-left: 10%;
    width: 75%;
    min-height: 100px;
    border: 1px dashed #aaa;
    padding: 5px;
    resize: none;
  }
  
 
  

  