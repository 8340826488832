.pngbackground{
    background-color: aliceblue;
    border-bottom: 4px solid #007bff;;
}
.pngcolor{
    color: #007bff !important;
}
.nav-justified .nav-item {
 flex-grow: 0 !important;
 /* flex-shrink: 1 !important; */
 flex-basis: 150px !important; 

flex-wrap:nowrap !important;

 /* color: black; */
}
.tabs-design ul{
border-bottom: solid 1px #80808040;
background-color: #f2f2f2 ;
flex-wrap: nowrap;

}
/* #3d5170 */
.tabs-design .nav-item a{
 color:#3d5170  ;
 font-size: 0.85rem;
 
}
